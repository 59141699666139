import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { FieldData } from "containers/BlockNoteRenderer/types";
import { setItem } from "utils/store";
import { updateFormData, updateFormFileData } from "./actions";
import { FormState, UpdateFormDataPayload } from "./types";

const initialState: FormState = { formData: {}, formFileData: {} };

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(
    updateFormData,
    (state, action: PayloadAction<UpdateFormDataPayload>) => {
      state.formData = { ...state.formData, ...action.payload.fieldValues };
      setItem(action.payload.savedKey, state.formData); // store form data in localstorage
    },
  );
  builder.addCase(
    updateFormFileData,
    (state, action: PayloadAction<FieldData>) => {
      state.formFileData = { ...state.formFileData, ...action.payload };
    },
  );
});

export default reducer;
