import React, { memo } from "react";
import { filter, map, get, pickBy, reduce, set, find } from "lodash";
import {
  DragHandleMenu,
  RemoveBlockItem,
  AddBlockButton,
  DragHandleButton,
  SideMenu as BlockNoteSideMenu,
} from "@blocknote/react";
import { Block } from "@blocknote/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faCodeFork,
  faEye,
  faEyeSlash,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import MenuBox from "components/Popover/MenuPopover/MenuBox";
import { propertyBox, validationBox } from "components/Block/blockMenuList";
import {
  customBlocksTypes,
  logicSupported,
} from "components/Tools/CustomBlocks";
import { schema } from "components/BlockNoteEditor";
import MenuButton from "components/SideMenu/MenuButton";

const isDuplicateName = (doc: any[], val: string, blockId: string) =>
  find(doc, (block) => block?.props?.attrkey === val && block?.id !== blockId);

export const fetchProperties = (
  fieldProps: any,
  editorDoc?: any,
  blockId?: string,
) => {
  return reduce(
    fieldProps,
    (result, item) => {
      // ignore value if has validation error
      if (!item?.error) {
        // ignore value if field is disabled
        let value = item?.isEnabled === false ? null : item?.value;

        // check for duplicate name
        if (
          blockId &&
          item?.value &&
          item?.key === "attrkey" &&
          isDuplicateName(editorDoc, item.value, blockId)
        )
          value += "_copy";

        set(result, item?.key, value);
      }
      return result;
    },
    {},
  );
};

export const onMenuclose = (
  block: Block,
  unfreezeMenu: () => void,
  menuFieldsRef: any,
  editor: typeof schema.BlockNoteEditor,
) => {
  const fieldProps = menuFieldsRef?.current;
  if (!fieldProps) return;
  const properties = fetchProperties(fieldProps, editor?.document, block?.id);

  const updatedToggleState = Object.fromEntries(
    fieldProps
      .filter((item: any) => item?.hasToggle)
      .map((item: any) => [item?.key, item?.isEnabled ?? false]),
  );

  try {
    editor?.updateBlock(block, {
      props: { ...properties, toggleState: updatedToggleState },
    });
  } catch (error) {
    console.error("Error updating block properties", error);
  }
  unfreezeMenu();
  menuFieldsRef.current = null;
};

export const getActionBlock = (data: any) => {
  const attrKeyItem = find(
    data,
    (item) => item?.key === "attrkey" && item?.value,
  );
  if (attrKeyItem)
    return { label: attrKeyItem.value, value: attrKeyItem.value };

  const labelItem = find(data, (item) => item?.key === "label" && item?.value);
  return labelItem ? { label: labelItem.value, value: labelItem.value } : null;
};

interface SideMenuProps {
  block: Block;
  unfreezeMenu: () => void;
  addBlock: (block: Block) => void;
  editor: typeof schema.BlockNoteEditor;
  menuFieldsRef: any;
  selectFieldTypes: any[];
}

const SideMenu: React.FC<SideMenuProps> = (props: any) => {
  const {
    block,
    unfreezeMenu,
    addBlock,
    editor,
    menuFieldsRef,
    selectFieldTypes,
  } = props || {};
  const { props: blockProps, type } = block || {};
  const isCustomBlock =
    Object.keys(customBlocksTypes)?.includes(type) &&
    type !== customBlocksTypes.logic;
  const filteredProps = pickBy(blockProps, (value) => value !== null);
  const isHidden = blockProps?.hide;

  const selectFields = filter(
    editor.document,
    (blockEl) =>
      blockEl?.type === "select" &&
      blockEl?.id !== block?.id &&
      blockEl?.props?.selectFieldType,
  );

  const selectParentFields = map(selectFields, (selectField) => ({
    label: get(selectField, "props.attrkey"),
    value: get(selectField, "props.selectFieldType.value"),
  }));

  const onAddLogic = () => {
    const actionBlock = getActionBlock(menuFieldsRef.current);
    editor?.insertBlocks(
      [
        {
          type: "logic",
          props: {
            actionBlock: actionBlock || null,
          },
        },
      ],
      block,
      "after",
    );
  };

  const onDuplicateClick = () => {
    const { attrkey, label } = blockProps || {};
    editor?.insertBlocks(
      [
        {
          ...block,
          id: undefined,
          props: {
            ...blockProps,
            ...(attrkey && { attrkey: `${attrkey}_copy` }),
            ...(label && { label: `${label}_copy` }),
          },
        },
      ],
      block,
      "after",
    );
  };

  const onHideAndShow = () =>
    editor?.updateBlock(block, { props: { hide: !isHidden } });

  const menuBtnItems = [
    {
      onClick: onHideAndShow,
      label: isHidden ? "Show" : "Hide",
      icon: isHidden ? faEye : faEyeSlash,
      show: true,
    },
    {
      onClick: onDuplicateClick,
      label: "Duplicate",
      icon: faClone,
      show: true,
    },
    {
      onClick: onAddLogic,
      label: "Add conditional logic",
      icon: faCodeFork,
      show: logicSupported?.includes(type),
    },
  ];

  return (
    <BlockNoteSideMenu {...props}>
      <FontAwesomeIcon
        icon={faTrashCan}
        className="text-gray-400 cursor-pointer hover:bg-gray-200 rounded-md p-1.5"
        onClick={() => editor?.removeBlocks([block])}
        data-testid="trash-can"
      />
      <AddBlockButton addBlock={addBlock} />
      <DragHandleButton
        {...props}
        unfreezeMenu={() =>
          onMenuclose(block, unfreezeMenu, menuFieldsRef, editor)
        }
        dragHandleMenu={(props) => {
          return (
            <DragHandleMenu {...props}>
              {isCustomBlock && (
                <>
                  <MenuBox
                    propertyBox={propertyBox[type]}
                    validations={validationBox[type]}
                    block={{ type, properties: filteredProps }}
                    menuFieldsRef={menuFieldsRef}
                    selectFieldTypes={selectFieldTypes}
                    selectParentFields={selectParentFields}
                  />
                  <div className="border-[0.5px] border-gray-200"></div>
                </>
              )}
              <RemoveBlockItem {...props}>
                <div>
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="text-gray-600 mr-2"
                  />
                  Delete
                </div>
              </RemoveBlockItem>
              {map(menuBtnItems, (item, index) => {
                return (
                  item.show && (
                    <MenuButton
                      key={index}
                      onClick={item.onClick}
                      label={item.label}
                      icon={item.icon}
                    />
                  )
                );
              })}
            </DragHandleMenu>
          );
        }}
      />
    </BlockNoteSideMenu>
  );
};

export default memo(SideMenu);
