/**
 *
 * Input
 *
 */

import { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import classNames from "classnames";
import { find, round, get } from "lodash";
import { AddImage } from "images/AddImage";
import { DeleteIcon } from "images/DeleteIcon";
import { useUploadFileMutation } from "store/services/updateFormBuilder";
import { uploadFileToS3 } from "store/services/api";
import { updateFormFileData } from "store/actions";
import { getItem } from "utils/store";
import { UPLOAD_STATUS } from "utils/constant";
import Tooltip from "components/Tooltip";
import { FileOptions } from "components/Block/blockMenuList";
import MarkdownWrapper from "components/MarkdownWrapper";
import Loader from "components/Loader";

export const File = (props: any) => {
  const {
    required,
    label,
    name,
    disabled,
    fileTypes,
    size,
    error,
    onChange,
    readOnly,
    tooltip,
    attrkey,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const { user_id } = getItem("user_details") || {};
  const inputRef = useRef<any>(null);
  const [fileSelected, setFileSelected] = useState<any>(null);

  const [uploadFile] = useUploadFileMutation();

  const onFileUploadSuccess = (fileId: number) => {
    const file = get(inputRef.current, "files.0");
    setFileSelected((prev: any) => ({
      ...prev,
      uploadStatus: UPLOAD_STATUS.SUCCESS,
    }));
    onChange?.({ file, fileId });
  };

  const onFileUploadFailure = () => {
    setFileSelected((prev: any) => ({
      ...prev,
      uploadStatus: UPLOAD_STATUS.FAILED,
    }));
    inputRef.current.value = "";
  };

  const uploadCallback = (res: any) => {
    const { id, presigned_url } = get(res, "data.file_upload_file", {});
    const file = get(inputRef.current, "files.0");

    if (!presigned_url || !file) onFileUploadFailure();
    else
      uploadFileToS3(
        presigned_url,
        file,
        id,
        onFileUploadSuccess,
        onFileUploadFailure,
      );
  };

  const onFileSelect = (e: any) => {
    const file = get(e, "target.files.0");

    if (!file) return;
    const fileName = get(file, "name");
    const fileType = fileName?.split(".")?.pop();
    setFileSelected({
      fileName,
      fileType,
      fileSize: round(get(file, "size", 0) / (1024 * 1024), 2) || 0,
      uploadStatus: UPLOAD_STATUS.UPLOADING,
    });
    uploadFile({ fileName, fileType, user_id, uploadCallback });
  };

  const onClearFile = (e: any) => {
    setFileSelected(null);
    inputRef.current.value = "";
    dispatch(updateFormFileData({ [attrkey]: null }));
    e.stopPropagation();
  };

  const getFileTrailingIcon = () => {
    switch (fileSelected?.uploadStatus) {
      case UPLOAD_STATUS.UPLOADING:
        return <Loader />;
      case UPLOAD_STATUS.SUCCESS:
        return <DeleteIcon />;
      case UPLOAD_STATUS.FAILED:
        return (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="text-red-400"
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    inputRef.current?.setCustomValidity(
      required && !fileSelected
        ? `This is a required field`
        : fileSelected?.fileSize > parseFloat(size)
          ? `Exceeds file size of ${size} MB`
          : "",
    );
  }, [fileSelected]);

  const fileTypeList = (fileTypes?.length > 0 ? fileTypes : FileOptions)
    .map((type: any) => type.name)
    .join(", ");

  return (
    <div
      className={classNames(
        "gap-8 cursor-pointer bg-white",
        disabled && "opacity-50",
      )}
    >
      <div className="flex">
        {label ? (
          <label
            htmlFor={name}
            className={`flex items-center text-sm mb-2 font-normal text-stone-900 not-italic
              ${disabled && "group group-hover:cursor-pointer"}
            `}
            data-testid="input-label"
          >
            <MarkdownWrapper>{required ? label + "*" : label}</MarkdownWrapper>
            {tooltip && <Tooltip tooltipId={label} content={tooltip} />}
          </label>
        ) : (
          <div />
        )}
        <input
          {...rest}
          ref={inputRef}
          type="file"
          accept={fileTypes?.map((type: any) => type.ext.join(",")).join(",")}
          capture="user"
          name={name}
          onChange={onFileSelect}
          id={name}
          className="opacity-0 pointer-events-none w-0.5"
          aria-describedby={`${name}-error`}
          data-testid={rest["data-testid"] || "input"}
          role="button"
        />
      </div>
      <div
        className="flex gap-3 rounded-md shadow-sm w-full border-2 border-dashed p-2 max-w-[25rem]"
        onClick={() =>
          !disabled &&
          !readOnly &&
          !(fileSelected?.uploadStatus === UPLOAD_STATUS.UPLOADING) &&
          inputRef.current.click()
        }
      >
        <AddImage />
        <div className="flex flex-col justify-center gap-1">
          <div className="group-cursor-pointer justify-start items-center gap-1 inline-flex cursor-pointer">
            {fileSelected ? (
              <div className="flex gap-2 text-gray-600 text-sm font-medium leading-tight">
                <div className="truncate max-w-56">
                  {fileSelected?.fileName || "--"}
                </div>
                <div data-testid="cancel_button" onClick={onClearFile}>
                  {getFileTrailingIcon()}
                </div>
              </div>
            ) : (
              <div className="text-sm font-medium leading-tight text-blue-600">
                Upload a file
              </div>
            )}
          </div>
          <div className="text-left text-gray-500 text-xs font-normal leading-none">
            {!fileSelected ? (
              <div
                className={"w-60 text-gray-500 text-xs font-normal"}
              >{`${fileTypeList} ${size ? `up to ${size} MB` : ""}`}</div>
            ) : (
              `${
                find(FileOptions, (t) => t.ext.includes(fileSelected?.fileType))
                  ?.name
              } ${fileSelected?.fileSize || "--"} MB`
            )}
          </div>
        </div>
      </div>
      {error && (
        <p
          className="font-normal text-xs text-rose-800 mt-2"
          id={`${name}-description`}
          data-testid="input_error_msg"
        >
          {error}
        </p>
      )}
    </div>
  );
};

File.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.number,
  fileTypes: PropTypes.array,
};

export default memo(File);
