import { createAction } from "@reduxjs/toolkit";
import { FieldData } from "containers/BlockNoteRenderer/types";
import { UpdateFormDataPayload } from "store/types";
import { UPDATE_FORM_DATA, UPDATE_FORM_FILE_DATA } from "./constants";

export const updateFormData =
  createAction<UpdateFormDataPayload>(UPDATE_FORM_DATA);

export const updateFormFileData = createAction<FieldData>(
  UPDATE_FORM_FILE_DATA,
);
