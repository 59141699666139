import _ from "lodash";
import { setItem } from "utils/store";

export const isDate = (d: string) => {
  if (!d) return true;
  const date = d.trim();
  const isDateFormat = !(
    date.split("-").filter((el: string) => el === "").length > 0
  );

  if (
    date.split("-").length !== 3 ||
    !isDateFormat ||
    isNaN(Date.parse(date))
  ) {
    return false;
  }

  return true;
};

export const isDuplicate = (arr: any[]) => {
  const labelArr = arr.map((item: any) => item.label);
  const valueArr = arr.map((item: any) => item.value);
  const isDuplicateValue = valueArr.some((item: any, idx: number) => {
    return valueArr.indexOf(item) !== idx;
  });
  const isDuplicateLabel = labelArr.some((item: any, idx: number) => {
    return labelArr.indexOf(item) !== idx;
  });

  return !(isDuplicateLabel || isDuplicateValue);
};

export const isArrayEqual = (x: any, y: any) => {
  return _(x).differenceWith(y, _.isEqual).isEmpty();
};

const roleMap = ["TENANT_ADMIN", "EVALUATOR", "MASTER_RECRUITER", "RECRUITER"];

export const parseJwt = (token: string) => {
  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0)?.toString(16)}`.slice(-2)}`)
        .join(""),
    );
    return JSON.parse(jsonPayload);
  }
  return {};
};

export const processToken = (access_token: string) => {
  const user = parseJwt(access_token);
  const role_list = _.get(
    user,
    "['https://hasura.io/jwt/claims']['X-Hasura-Allowed-Roles']",
    [],
  );
  const roles = [
    ..._.intersection(roleMap, role_list),
    ..._.difference(role_list, roleMap),
  ];
  const default_role = _.get(roles, "[0]", null);
  const user_id = _.get(
    user,
    "['https://hasura.io/jwt/claims']['X-Hasura-User-Id']",
    null,
  );
  const tenant_id = _.get(
    user,
    "['https://hasura.io/jwt/claims']['X-Hasura-Tenant-Id']",
    null,
  );
  const processed_obj = {
    access_token,
    user,
    default_role,
    roles,
    user_id,
    tenant_id,
  };
  setItem("user_details", processed_obj);
  return processed_obj;
};
