import React from "react";
import Markdown from "react-markdown";
import classNames from "classnames";

interface MarkdownWrapperProps {
  children: string;
  prose?: boolean;
}

const MarkdownWrapper = ({ children, prose = false }: MarkdownWrapperProps) => {
  return (
    <Markdown
      components={{
        a: ({ href, children }) => (
          <a
            href={href}
            target="_blank"
            className="text-blue-400 no-underline"
            rel="noreferrer"
          >
            {children}
          </a>
        ),
      }}
      className={classNames(prose && "prose max-w-full")}
    >
      {children}
    </Markdown>
  );
};

export default MarkdownWrapper;
