import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import store from "store";
import { growthbook } from "utils/growthbook";
import Home from "Pages/Home";
import Editor from "Pages/Editor";
import BlockNoteRenderer from "containers/BlockNoteRenderer";
import Auth from "containers/Auth";

function App() {
  useEffect(() => {
    growthbook.loadFeatures();
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/builder/:id"
              element={
                <Auth>
                  <Editor />
                </Auth>
              }
            />
            <Route
              path="/renderer/:id"
              element={
                <Auth>
                  <BlockNoteRenderer />
                </Auth>
              }
            />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </GrowthBookProvider>
  );
}

export default App;
